.gradient-primary,
.table-header-wrapper table,
table thead {
    background: -webkit-linear-gradient(
        left,
        rgba(49, 144, 177, 1) 0%,
        rgba(49, 144, 177, 1) 5%,
        #3588a8 36%,
        #3588a8 70%,
        #307b98 100%
    ) !important;
}
.table-header-wrapper table tbody {
    background-color: #fff;
}
.btn-primary:focus {
    background-color: var(--primaryColor);
}
.btn-cerrar:hover {
    border-color: #5a69f9 !important;
}
.btn-blanco {
    background-color: #fff;
    color: var(--primaryColor);
    box-shadow: none;
    min-width: 100px;
    height: 34px;
    min-height: 34px;
    font-size: 17px;
    padding-top: 5px;
}
.btn-blanco:hover {
    background-color: #f2f2f2;
}
.btn-subir {
    background-color: #5a69f9;
    color: #fff;
    border-radius: 5px;
    border: 1ox solid rgba(0, 0, 0, 0.5);
    margin: 0px 2px 0px 2px;
}
.btn-subir:focus {
    background-color: #3a3999;
    color: #fff;
    border-radius: 5px;
    border: none;
}
.cantidadACarrito {
    padding-left: 0px;
    padding-right: 0px;
    border: 1px solid rgba(0, 0, 0, 0.5);
    border-radius: 5px;
    display: flex;
    justify-content: end;
    text-align: center;
}
.cantidadACarrito:focus {
    border: 1px solid rgba(0, 0, 0, 0.5);
}
.shadow,
.RSPBprogressBar {
    -webkit-box-shadow: 0px 3px 34px 5px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 0px 3px 34px 5px rgba(0, 0, 0, 0.25);
    box-shadow: 0 0.3rem 0.5rem rgba(0, 0, 0, 0.25) !important;
}

.title-navbar {
    display: flex;
    align-items: center;
    margin-bottom: 0;
    margin-left: 2rem;
    color: var(--primaryColor);
}

.navbar-title {
    margin-right: 3rem;
    margin-left: 0.5rem;
    border-radius: 15px;
    width: 100%;
    display: flex;
    align-items: center;
}

.usuario {
    font-size: 20px;
    color: var(--primaryColor);
}

.menu-usuario {
    min-width: 200px;
    height: 3rem;
    border-radius: 15px;
    align-items: center;
    display: flex !important;
    justify-content: center;
}

.rc-tabs-nav {
    margin-bottom: 5px !important;
    font-size: 1.1rem;
}

.readFieldOnly input {
    background-color: transparent !important;
    border: none !important;
    cursor: default !important;
}

.titulo-rol {
    background: -webkit-linear-gradient(
        top,
        #19d09a 50%,
        #2ad5a1 100%
    ) !important;
    border-radius: 6px;
    padding: 5px 10px;
    color: #000;
}

.react-bs-table-pagination {
    display: table-caption !important;
}

.SingleDatePickerInput__withBorder,
.css-1szy77t-control {
    border-radius: 10px !important;
}

/* --------- Barra de Progreso Superior ------------ */
.RSPBprogressBar {
    height: 60px;
    /*border: 1px solid #a0a0c7;*/
    background-color: #fff;
}
.RSPBprogressBar .RSPBstep {
    font-weight: bolder;
}
.RSPBprogressBar .RSPBprogression {
    border-radius: 10px;
    background-color: transparent !important; /*#ADD5E3 !important;*/
}
/* ------------------------------------------------- */

.nav-link .caret {
    float: right;
    width: 15px;
    transform: scale(0.7);
}

.roles .label.needsclick {
    font-size: 0.8rem;
}

/*  ----------------- modal -----------------------*/

.modal-card-header {
    background-color: transparent;
    padding-bottom: 1rem;
    padding-left: 4px !important;
    padding-right: 4px !important;
    display: flex;
    align-items: center;
    width: 100%;
    font-size: 1rem;
    font-weight: bolder;
    height: 90px;
    left: -55px;
    top: -70px;
}

.modal-card-title {
    color: #fff;
    background: -webkit-linear-gradient(
        left,
        rgba(49, 144, 177, 1) 0%,
        rgba(49, 144, 177, 1) 5%,
        rgba(56, 121, 206, 1) 36%,
        rgba(60, 102, 226, 1) 70%,
        rgba(68, 77, 253, 1) 100%
    );
    font-size: 1.1em;
    border-radius: 10px;
    align-items: center;
    padding-left: 2rem !important;
    height: 45px;
    display: flex;
    margin-bottom: 5px;
    font-family: "Cairo-Bold";
}

.colorOptionModal label {
    color: var(--primaryColor) !important;
    font-weight: bolder !important;
}

/* ----------------------------------------------------------*/

/* ------------------- Iconos de acciones ------------------------*/
.iconoRotar {
    transform: rotate(-45deg);
}
.iconoRedondo {
    border-radius: 50%;
    padding: 3px;
}
.iconoEliminarCarrito {
    border: 2px solid #fa5383;
    color: #fa5383;
    padding: 0px 0px 0px 1px;
}
.iconoAcciones {
    border-radius: 5px;
    padding: 3px;
}
.iconoAcciones:hover,
.iconoEliminarTabla:hover,
.iconoEditarTabla:hover .iconoEliminarCarrito {
    transform: scale(1.2);
}
.iconoAgregarCarrito:hover {
    transform: rotate(-45deg) scale(1.2);
}
.iconoEliminarTabla,
.iconoEditarTabla {
    border-radius: 5px;
    padding: 8px;
    cursor: pointer;
}
.iconoVer {
    background: #ffdb0b;
}
.iconoVer:hover {
    background: rgb(248, 191, 66);
}
.iconoAgregarCarrito {
    background: #fe366d;
}
.iconoEliminar {
    background: #fa5383;
}
.iconoEliminar:hover,
.iconoAgregarCarrito:hover {
    background: rgb(173, 30, 71);
}
.iconoEditar,
.iconoReajuste {
    background: #5d66fa;
}
.iconoEditar:hover,
.iconoReajuste:hover {
    background: rgb(37, 44, 185);
}
.iconoAgenda,
.iconoImprimir {
    background: var(--primaryColor);
}
.iconoAgenda:hover,
.iconoImprimir:hover {
    background: rgb(18, 97, 126);
}

/* ---------------------------------------------------------------*/

/* ----------------------- TABS PUNTO DE VENTA -------------------*/
.tabPuntoVenta .rc-tabs-nav-wrap {
    background: transparent !important;
}
.tabPuntoVenta .rc-tabs-tab {
    background-color: transparent !important;
    font-family: "Cairo-Regular", sans-serif;
    color: #3290b3 !important;
    color: var(--primaryColor) !important;
    border: 0;
    border-image-source: None;
    border-bottom: 2px solid #8faeb4;
    border-radius: 0;
}
.tabPuntoVenta .rc-tabs-tab-active,
.rc-tabs-tab-active {
    border-bottom: 2px solid;
    color: #3290b3 !important;
    font-weight: bolder;
}

.tabDentroDeTab .rc-tabs-tab {
    background-color: transparent !important;
    color: var(--primaryColor) !important;
    font-family: "Cairo-SemiBold", sans-serif;
    border-bottom: 2px solid #8faeb4;
    background-color: #d3d3d3 !important;
    font-family: "Cairo-Regular", sans-serif;
    color: var(--primaryColor) !important;
    border: 20px solid white;
    border-bottom: 0;
    border-left: 0;
    border-top: 0;
    border-image-source: url(../assets/img/pane2.png);
    border-image-slice: 56;
    border-radius: 10px 28px 0px 10px;
}
.tabDentroDeTab .rc-tabs-tab {
    padding: 5px 35px 5px 35px !important;
}
.tabDentroDeTab .rc-tabs-tab-active.rc-tabs-tab {
    background-color: #3290b3 !important;
    font-family: "Cairo-Bold", sans-serif;
    color: #3290b3 !important;
    color: #3290b3 !important;
    color: white !important;
    border: 20px solid silver;
    border-bottom: 0;
    border-left: 0;
    border-top: 0;
    border-image-source: url(../assets/img/pane.png);
    border-image-slice: 56;
}

@media (max-width: 575px) {
    .navbar-title {
        margin-right: 0;
        margin-left: 0;
        border-radius: 15px;
    }

    .menu-usuario {
        margin-left: 0;
    }
}

@media (max-width: 768px) {
    .navbar-title {
        margin: 0 0 0 0.4rem;
        padding: 0;
        border-radius: 15px 0 0 15px;
        display: flex;
        align-items: center;
    }
    .title-navbar {
        margin-left: 0.5rem;
        font-size: 13px;
        line-height: 20px;
    }
    .usuario {
        font-size: 13px;
    }
    .menu-usuario {
        min-width: 150px;
        border-radius: 0 !important;
        box-shadow: 0 0.3rem 0.5rem rgba(0, 0, 0, 0) !important;
        -webkit-box-shadow: none !important;
    }
    .navbar-nav {
        box-shadow: 0.4rem 0.2rem 0.5rem rgba(0, 0, 0, 0.3);
        border-radius: 0 10px 10px 0;
    }
    .nav {
        background: #fff;
        height: 100%;
        display: flex;
        align-items: center;
        padding: 0 5px;
        border-radius: 0 10px 10px 0;
    }
}
