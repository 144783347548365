.root{
    --altura: 70vh;
}

.login-overlay .panel-body{
    min-height: 244px;
}
.has-feedback{
    position: relative;
}
.form-control-feedback{
    right: 10px;
    left: auto;
    top: 10px;
    position: absolute;
}
.login-wrapper{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1% 0 1% 1% !important;
    border-bottom: 4px dashed #fff;
    border-left: 4px dashed #fff;
    border-top: 4px dashed #fff;
    height: auto;
    border-radius: 3% 0 0 3%;
}
.card-login {
    border: 0;
    border-radius: 3% 0 0 3% !important;
    /* width: 100%; */
    height: 70vh;
    /* box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.1); */
    box-shadow: none !important;
    padding: 2rem;
    background: white;
    min-width: 413px;
}

div >label{color: var(--mostaza); font-weight: bold;}
div> span{ color:#939393; font-family: "Cairo-SemiBold"; }
div >span> a {color: var(--primaryColor);}

.main-sidebar div > span{
    color:#fff; font-family: "Cairo-SemiBold"; 
}

.bienvenida{
    color:black;
    font-weight: bold;
    background: white;
    height: 70vh;
    text-align: center;
    justify-content: center;
    align-items: center;
    display: flex;
}
.buttons-box{
    display: flex;
    align-items: center;
    align-items: center;
}
.blue-gradient-bg{
    height: 100vh;
    background: url('../../../../../assets/img/background.png') var(--azul-obscuro) !important;
    background-repeat: no-repeat !important;
    background-size: 100% 100% !important;
    display: flex;
}
.login{
    align-items: center;
}


@media only screen and (max-width: 800px) {
    /* For mobile phones: */
    
    /* .blue-gradient-bg{
        display: block !important; 
    } */
    .bienvenida{
        display: none !important;
        visibility: hidden;
    }
    .login-wrapper{
        width: 500px !important;
        max-width: 100%;
    }
    .card-login {
        padding: 40px !important;
        min-height: 450px !important;
        min-width: 270px !important;
    }
    .logo{
        width: 115px !important;
        height: 115px !important;
    }
}

.imagen{
    width: 100%;
}

.logo{
    min-width: 80px;
    width: 40%;
    height: 30%;
    align-self: center;
}

.text-left{
    padding-left: 2.1rem;
}

@media (max-width: 576px) {
    .card-login{
        border-radius: 3% 3% 0 0 !important;
        padding-bottom: 0rem !important;
        margin-bottom: 0rem !important;
        height: 65vh;
    }
    .login-wrapper{
        padding: 2% 2% 0 2% !important;
        border-radius: 3% 3% 0% 0%;
        border-top: 3px dashed #fff;
        border-left: 3px dashed #fff;
        border-right: 3px dashed #fff;
        border-bottom: 0;
    }

    .blue-gradient-bg{
        height: 100vh;
        background: url('../../../../../assets/img/background-login-responsive.png') var(--azul-obscuro) !important;
        background-repeat: no-repeat !important;
        background-size: 100% 100% !important;
        padding: 0 1rem;
    }
    .text-left{
        padding-left: 0;
    }
    .text-center{
        margin-top: 1rem !important;
    }
    .login{
        align-items: flex-end;
    }
}

@media (min-width: 1200px){
    .card-login{
        width: 100%;
    }
}