@import "fonts.css";
/*  COLORES

--azul-obscuro: #00334e;
--azul: #558198;
--azul-light: #e9f8fd;
--mostaza: #f3a631;
--claro: #e8e8e8;
--musgo: #434343;
--error: #ad1717;
--primaryColor: #3290B3;
*/
/* -----------------------------FONTS----------------------------- */

body {
    font-family: "Cairo-regular", sans-serif;
}
th {
    font-family: "Cairo-semibold", sans-serif;
}
.btn {
    font-family: "Cairo-bold", sans-serif;
}
h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: "Cairo-Bold", sans-serif;
}
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
    font-family: "Cairo-regular", sans-serif;
}
input {
    font-family: "Cairo-regular", sans-serif;
    color: var(--musgo);
}
.card {
    border-radius: 0;
}

.bold-sidebar {
    font-family: "Cairo-bold", sans-serif;
}

.semibold {
    font-family: "Cairo-semibold", sans-serif;
}
.bold {
    font-family: "Cairo-bold", sans-serif;
}
.fnt-size {
    font-size: 16px;
}
/* -----------------------------icons----------------------------- */
.i-mostaza {
    color: white !important;
    background-color: var(--mostaza);
    border-radius: 1rem !important;
}
.i-azul-obscuro {
    color: white !important;
    background-color: var(--azul-obscuro);
    border-radius: 1rem !important;
}
.i-azul {
    color: var(--azul) !important;
}
.action-img {
    width: 18px;
    height: 18px;
}
.action-img-ver {
    width: 20px;
    height: 20px;
}
.with-40 {
    width: 40px;
}
/* -----------------------------notificaciones----------------------------- */
.notification-success {
    background-color: var(--azul-obscuro) !important;
}
.notification-error {
    background-color: var(--error) !important;
}
.notification-info {
    background-color: var(--azul-obscuro) !important;
    border-top: none !important;
}
/* -----------------------------navbar----------------------------- */
.bg-primary {
    background-color: var(--azul-obscuro) !important;
    color: white;
}
.bg-white {
    background-color: white;
}

.bs-none {
    box-shadow: 0 !important;
}

.bs-nav {
    box-shadow: 0 0.125rem 9.375rem rgba(90, 97, 105, 0.1),
        0 0.25rem 0.5rem rgba(90, 97, 105, 0.12),
        0 0.9375rem 1.375rem rgba(90, 97, 105, 0.1),
        0 0.4375rem 2.1875rem rgba(165, 182, 201, 0.1);
}

/* -----------------------------grids----------------------------- */
tr > th {
    background-color: transparent; /* var(--azul-obscuro) !important; */
    color: white;
    border: 0 !important;
    font-family: "Cairo-Bold";
}
.color-header-blanco tr > th {
    background-color: white !important;
    color: #f3a631;
    border: 0 !important;
}
.color-header-blanco .table td,
.table th {
    border-top: 0px solid #dee2e6 !important;
}
.color-header-tabla tr > th {
    background-color: transparent !important;
    color: white !important;
    border: 0 !important;
}
/* .page-item.active > a{
    background-color: var(--mostaza) !important;
    color: white !important;
} */
.page-item.active .page-link {
    background-color: var(--pageLink) !important;
    color: #fff !important;
}

.table-hover tbody tr:hover td {
    background: var(--azul-light);
}
.over-h {
    overflow: hidden;
}

.reporte-table {
    background-color: white !important;
    color: var(--azul-obscuro) !important;
}

.reporte-table-special {
    background-color: white !important;
    color: #f3a631 !important;
}

.reporte-table .react-bs-table .table-bordered > thead > tr:first-child > th {
    background-color: transparent !important;
}
/* -----------------------------titulos----------------------------- */
.t-negro{
    color: #212529;
}
.t-primary {
    color: var(--primaryColor) !important;
}
.t-azul-claro {
    color: var(--azul);
}
.t-morado {
    color: var(--btnCerrar);
}
.t-azul {
    color: var(--azul);
}
.t-mostaza {
    color: var(--mostaza);
}
.t-musgo {
    color: var(--musgo);
}
.t-azul-btn {
    color: var(--azul-btn);
}
.t-red {
    color: var(--error);
}
.t-blanco {
    color: #fff !important;
}
.t-texto {
    color: var(--color-texto);
}
.t-titulo {
    color: var(--color-titulo);
}
.m-t {
    margin: 30px 0 20px 0;
}
.mbtn {
    margin-top: 10px;
}
.m-l {
    margin: 0 0 0 10px;
}
.p-t {
    padding: 15px;
}
.p-r {
    padding-right: 45px;
}
.p-b {
    padding-bottom: 1rem !important;
}
/* alineado y justificado */
.tend {
    text-align: end;
}

.df {
    display: flex;
}

.t-h {
    font-size: 25px !important;
}
.t-g {
    font-size: 10px !important;
}
.t-verde {
    color: #8fa54e !important;
}
.t-corinto {
    color: #ec577d !important;
}
.m-none {
    margin: 0;
}
/* -----------------------------SIDEBAR ----------------------------- */
.dashbord-img {
    width: 20px;
    height: 20px;
    margin-right: 8px;
}

.Cuentas-img {
    width: 19px;
    height: 18px;
    margin-right: 8px;
}

.sub-nav-item {
    font-size: 17px;
    padding: 0 0 0 45px;
    font-family: "Barlow Condensed-regular", sans-serif;
}

.active-M {
    /* border-left: 5px solid var(--mostaza);
    background-color: #f3a53118; */
    background-color: #93b1bc;
    border-radius: 10px;
    margin: 3px;
}
.bg-submenu {
    /* background-color: var(--azul-light); */
    background-color: #93b1bc;
    border-radius: 10px;
    margin: 5px 3px;
    padding: 15px 0;
}

.submenu-select,
.submenu-select > div > span {
    font-family: "Cairo-Bold", sans-serif;
    color: #fff !important;
    /* See https://developer.mozilla.org/en-US/docs/Web/CSS/list-style-type     */
}
.submenu-select,
.submenu-select > div::before {
    display: list-item; /* This has to be "list-item"                                               */
    list-style-type: disc;
    color: #fff !important;
}
.dot-seleccionado {
    height: 25px;
    width: 25px;
    font-weight: bold;
    background-color: #9e0b0f;
    border-radius: 50%;
    color: white;
    text-decoration: none !important;
    z-index: 10;
    position: relative;
    bottom: -8px;
    right: 0;
    -webkit-box-shadow: -1px 0px 7px 0px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: -1px 0px 7px 0px rgba(0, 0, 0, 0.75);
    box-shadow: -1px 0px 7px 0px rgba(0, 0, 0, 0.75);
}
.dot {
    height: 10px;
    width: 10px;
    background-color: var(--mostaza);
    border-radius: 50%;
    position: absolute;
    top: 5px;
    left: -10px;
}

.d-none {
    display: none !important;
}

/* -----------------------------TRANSACCIONES ----------------------------- */
.b-r {
    border-right: 1px solid var(--azul);
}

.btn-t {
    width: 112px;
    height: 34px;
    font-size: 50px 22px !important;
    text-align: center;
    justify-content: center;
}

.shadow-card {
    -webkit-box-shadow: 0px 3px 6px #00000029;
    -moz-box-shadow: 0px 3px 6px #00000029;
    box-shadow: 0px 3px 6px #00000029;
}

/********------------------------DOTS ------------*/
.dot {
    height: 10px;
    width: 10px;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
}
.dot-sub-item::before {
    height: 10px;
    width: 10px;
    background-color: rebeccapurple;
    border-radius: 50%;
    display: inline-block;
}

/* -----------------------------TAPS ----------------------------- */
.rc-tabs-tab {
    background-color: transparent !important;
    color: var(--primaryColor) !important;
    font-family: "Cairo-SemiBold", sans-serif;
    border-bottom: 2px solid #8faeb4;
}
.rc-tabs-top .rc-tabs-tab {
    padding: 5px 35px 5px 35px !important;
}
.rc-tabs-tab-active.rc-tabs-tab {
    background-color: #3290b3 !important;
    font-family: "Cairo-Bold", sans-serif;
    color: #3290b3 !important;
    color: #3290b3 !important;
    color: white !important;
    border: 20px solid silver;
    border-bottom: 0;
    border-left: 0;
    border-top: 0;
    border-image-source: url(../assets/img/pane.png);
    border-image-slice: 56;
}

.rc-tabs-tab {
    background-color: #d3d3d3 !important;
    font-family: "Cairo-Regular", sans-serif;
    color: var(--primaryColor) !important;
    border: 20px solid white;
    border-bottom: 0;
    border-left: 0;
    border-top: 0;
    border-image-source: url(../assets/img/pane2.png);
    border-image-slice: 56;
    border-radius: 10px 28px 0px 10px;
}

.rc-tabs-ink-bar {
    /* background-color: var(--azul-obscuro) !important; */
    display: none !important;
}
.margin-tabgrid {
    margin-left: -16px;
    margin-top: -2px;
}

/* -----------------------------steps/progresbar ----------------------------- */
.step-caption {
    text-align: center;
    height: 30px;
    width: 30px;
    border-radius: 50%;
    background-color: #999999;
    color: var(--azul) !important;
    font-family: "Cairo_regular", sans-serif;
    border: 1px solid rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: center;
    align-items: center;
}

.step-caption-selected {
    text-align: center;
    height: 30px;
    width: 30px;
    border-radius: 50%;
    background-color: var(--primaryColor);
    color: white;
    font-family: "Cairo-Bold", sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
}

.step-caption-check {
    text-align: center;
    height: 30px;
    width: 30px;
    border-radius: 50%;
    background-color: #2ad5a1;
    color: white;
    font-family: "Cairo-Bold", sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
}

.dropop {
    background-color: white;
    color: var(--musgo);
    font-family: "Barlow Condensed-regular", sans-serif;
    font-weight: normal !important;
    font-size: 15px;
    height: 45px;
}

.css-bg1rzq-control {
    border-radius: 10px !important;
}
.css-bg1rzq-control:focus {
    border-radius: 10px !important;
    border-color: var(--primaryColor);
    box-shadow: 0 0 0 1px var(--azul-obscuro);
}

.css-1szy77t-control {
    border-radius: 0 !important;
    border-color: var(--azul-obscuro) !important;
    box-shadow: none !important;
}
.css-1szy77t-control:hover {
    border-color: var(--azul-obscuro) !important;
}

.react-bs-container-header {
    overflow: visible !important;
}

.btn-outline-secondary:hover {
    background-color: var(--azul);
    color: white;
}
.btn-outline-secondary:active {
    background-color: var(--azul);
    color: white;
}

.react-tagsinput-tag {
    background-color: var(--azul);
    color: white;
    font-family: "Barlow Condensed-regular", sans-serif;
    border: none;
    height: 24px;
    text-align: center;
    justify-items: center;
}

.react-tagsinput--focused {
    border-color: var(--azul-obscuro) !important;
}

.month {
    border-right: none;
    border-left: none;
}
.dropdown {
    border-color: #e8e8e8;
}

/***************************SPEECH-BUBBLE ********/

.speech-bubble {
    position: relative;
    background: white;
    border: 2px solid #e8e8e8;
    border-radius: 15px;
}

.speech-bubble:after {
    content: "";
    position: absolute;
    top: 0;
    left: 10%;
    width: 0;
    height: 0;
    border: 15px solid transparent;
    border-bottom-color: white;
    border-top: 0;
    border-left: 0;
    margin-left: px;
    margin-top: -15px;
}

/*************************    fcm      ***************************/

.nots {
    color: white !important;
    background-color: var(--azul-obscuro);
}

.table td,
.table th {
    vertical-align: top !important;
}

.pabs {
    position: absolute;
    top: 60px;
}

/*Configuración datepicker*/
.SingleDatePicker {
    position: relative;
    width: 100%;
    display: inline-block;
}

.SingleDatePickerInput {
    width: 100%;
    height: 38px;
}
.SingleDatePickerInput__withBorder {
}
.DateInput {
    width: 100%;
    background: transparent !important;
}
.CalendarDay__selected,
.CalendarDay__selected:active,
.CalendarDay__selected:hover {
    background: var(--azul-obscuro);
    color: #fff;
}

.DayPickerKeyboardShortcuts_show__bottomRight {
    display: none;
}
.DateInput_input__focused {
    border-radius: 1em !important;
}
.DateInput_input {
    border-radius: 1em !important;
    font-size: 1rem !important;
}
.is-invalid .SingleDatePickerInput {
    border-color: var(--azul-obscuro);
}
.DateInput_input__focused {
    outline: 0;
    background: #fff;
    border: 0;
    border-top: 0;
    border-right: 0;
    border-bottom: 0px solid #008489;
    border-left: 0;
}
.DateInput_input {
    height: 35px;
}

/*************************    cardsPM      ***************************/

.pmcard {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
    margin: 0 5px 15px 0;
    /* align-items: center !important; */
    /* display: flex; */
    max-width: 250px;
    min-width: 200px;
    height: 160px;
    border: 1px solid rgba(0, 0, 0, 0.4);
    border-radius: 5px;
}
.pm-title {
    display: flex;
    justify-content: flex-end;
    line-height: 20px;
}
.pm-span {
    display: flex;
    text-align: flex-start;
    line-height: 20px;
}
.pmicon {
    margin-top: 10px;
    background: #ffdb0b;
    border-radius: 2px;
    display: flex;
    justify-content: center;
}
.pm-icon a {
    text-decoration: none;
}

.pmiconCAJA {
    position: absolute;
    top: -1px;
    right: 0px;
}

/**** ************CONTENEDOR ÁRBOL*********** ****/
.contendor-arbol .rayado-opciones:nth-child(even) {
    background-color: red !important;
}

.fondo-arbol {
    background-color: rgba(0, 0, 0, 0.05);
}

.prueba > div > div {
    background-color: #ddd;
}

@media (min-width: 1024px) {
    .facturas-hr {
        display: none;
    }
}
/************************ t*****************************/
.estado-reporte-1 {
    background-color: #434343 !important;
    display: flex;
    height: 15px;
    width: 15px;
    border-radius: 50px;
    margin-right: 15px;
}
.estado-reporte-2 {
    background-color: var(--verde) !important;
    display: flex;
    height: 15px;
    width: 15px;
    border-radius: 50px;
    margin-right: 15px;
}
.estado-reporte-3 {
    background-color: var(--mostaza) !important;
    display: flex;
    height: 15px;
    width: 15px;
    border-radius: 50px;
    margin-right: 15px;
}
.estado-reporte-4 {
    background-color: var(--error) !important;
    display: flex;
    height: 15px;
    width: 15px;
    border-radius: 50px;
    margin-right: 15px;
}

.horizontal-scroll {
    max-width: 100%;
    overflow-x: auto;
}

.nvd3-svg {
    width: 100% !important;
}
