.root{
    --altura: 70vh;
}

.login-overlay .panel-body{
    min-height: 244px;
}
.has-feedback{
    position: relative;
}
.form-control-feedback{
    right: 10px;
    left: auto;
    top: 10px;
    position: absolute;
}
.login-wrapper-reset{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
}
.card-reset {
    border: 0;
    border-radius: 3% !important;
    /* width: 100%; */
    height: 70vh;
    /* box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.1); */
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    padding: 3rem !important;
    background: white;
    min-width: 413px;
    width: 100%;
    /* box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.1); */
}

.card-reset-header{
    background-color: transparent;
    padding-bottom: 1rem;
    padding-left: 4px !important;
    padding-right: 4px !important;
    display: flex;
    align-items: center;
    width: 100%;
    font-size: 1rem;
    font-weight: bolder;
    height: 50px;
}

.card-reset-link a{
    color: #fff;
}

.card-reset-title{
    color: #fff;
    background:  -webkit-linear-gradient(left,
    rgba(49,144,177,1) 0%, rgba(49,144,177,1) 50%,#1DDAA4 100%);
    border-radius: 15px;
    height: 40px;
    display: flex;
    align-items: center;
}

div >label{color: var(--color-texto) !important;}
div> h5{ color: var(--azul-obscuro); }
div > p {color: var(--azul);}

.bienvenida{
    color:black;
    font-weight: bold;
    background: white;
    height: 70vh;
}
.buttons-box{
    display: flex;
    align-items: center;
    justify-content: center;
}
.blue-gradient-bg{
    height: 100vh;
    background: #00334e;
    /* background: linear-gradient(to right, #0062E6, #3ac1ff); */
    display: flex;
}
.background-reset{
    height: 100vh;
    background: url('../../../../../assets/img/background-reset.png') var(--azul-obscuro) !important;
    background-repeat: no-repeat !important;
    background-size: 100% 100% !important;
    display: flex;
}

.logo{
    width: 50%;
    height: 40%;
    margin-bottom: 20px;
}
@media (max-width: 425px) {
    .card-reset-header{
        font-size: 0.7rem;
        padding-left: 0px !important;
        padding-right: 0 !important;
    }
    .card-reset{
        padding: 2rem !important;
    }
}

@media (max-width: 768px) {
    .card-reset{
        height: 85vh;
    }
}