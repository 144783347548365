$color-title: #721c24;
$color-navbar: #006FBD;

$primary: #007bff;


$azul-obscuro: #00334e;
$azul: #558198;
$azul-light: #e9f8fd;
$mostaza: #f3a631;
$claro: #e8e8e8;
$musgo: #434343;
$error: #ad1717;


/* ---------- HILOS BALAM -----------*/
$primaryColor: #3290B3;
$pageLink: #93B0BB;